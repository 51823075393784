<template>
  <span
    :class="{'error': true, 'error-inline-variant': inlineVariant}"
    v-text="errorMessage"
  />
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      required: false,
      default() {
        return null
      }
    },
    inlineVariant: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  color: $color__text--error;
  font-weight: 100;
  font-size: .9rem;
  white-space: pre-wrap;
}

.error-inline-variant {
  display: inline !important;
  white-space: pre;
}
</style>
