export default {
  props: {
    rules: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      errorMessage: null,
      fallbackValue: null
    }
  },
  methods: {
    async validate(value) {
      this.errorMessage = null
      if (!this.rules || this.rules.length <= 0) {
        return true
      }
      let isValid = true
      for (let fieldRule of this.rules) {
        if (!await fieldRule.validator(value)) {
          this.errorMessage = this.errorMessage !== null ? this.errorMessage + '\n' + this.$tv({messageKey: fieldRule.message.key, messageValues: fieldRule.message.options}) : this.$tv({messageKey: fieldRule.message.key, messageValues: fieldRule.message.options})
          if (typeof  fieldRule.fallbackValue === 'number') {
            this.fallbackValue = fieldRule.fallbackValue
          }
          isValid = false
        }
      }

      return isValid
    },
    getName() {
      return this.$attrs.name || ('enextformelement' + this.uuid)
    }
  }
}
